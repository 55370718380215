<template>
    <div class="view-bookings-organizer" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="userFeedbacks">{{ translates.navigation.button_route_bookingsMyTrips[lang] }}</div>
        </nav>
        <h1 class="view-bookings-organizer__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-bookings-organizer__content">
            <div v-if="bookings.length < 1" class="view-bookings-organizer__content__empty" v-html="translates[langUrl].noBookings[lang]"></div>
            <template v-for="(booking, i) in bookings">
                <div class="view-bookings-organizer__booking" :key="'booking' + i">
                    <div class="view-bookings-organizer__booking__trip">
                        {{ booking.trip.name }}
                    </div>
                    <div class="view-bookings-organizer__booking__user">
                        {{ booking.user.firstname }} {{ booking.user.lastname }}
                    </div>
                    <v-spacer/>
                    <div class="view-bookings-organizer__booking__dates">
                        <div class="view-bookings-organizer__booking__dates__start">
                            <div class="view-bookings-organizer__booking__dates__day">{{ booking.start | moment('dddd') }}</div>
                            {{ booking.start | moment('DD MMM YYYY') }}</div>
                        <img src="../../assets/ArrowCircleRight.svg" />
                        <div class="view-bookings-organizer__booking__dates__finish">
                            <div class="view-bookings-organizer__booking__dates__day">{{ booking.finish | moment('dddd') }}</div>
                            {{ booking.finish | moment('DD MMM YYYY') }}</div>
                    </div> 
                    <div class="view-bookings-organizer__booking__status">
                        {{ BOOKING_STATUS_TITLE[booking.status] }}
                    </div>
                    <!-- <div class="view-bookings-organizer__booking__price">
                        <div class="view-bookings-organizer__booking__price__value"><span v-html="priceFormated(booking.price, booking.currency)"></span> </div>
                    </div> -->
                    <div class="view-bookings-organizer__booking__info">
                        {{ translates[langUrl].bookingCreate[lang] }}:
                        {{ booking.createdAt | moment('DD MMM YYYY') }}
                    </div>
                    <!-- <BaseButton
                        class="button"
                        style="width: 100%"
                        @click="deleteBooking(booking)">{{ translates[langUrl].button_deleteBooking[lang] }}</BaseButton> -->
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS, BOOKING_STATUS_TITLE } from '@/vars';
    import { priceFormated } from '@/helpers';
    import BaseButton from '../../components/common/BaseButton';
    import { isMobile } from '@/helpers';

    export default {
        name: 'Bookings',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton
        },
        data: () => ({
            BOOKING_STATUS,
            BOOKING_STATUS_TITLE,
            priceFormated,
            isDesktop: false,
            langUrl: '/user/bookings/organizer'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        mounted() {
            this.$root.$emit('preloaderShow');
            store.dispatch('bookings/fetchOrganizer', {});
            this.onResize();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async deleteBooking(booking) {
                await store.dispatch('bookings/delete', { id: booking._id });
                await store.dispatch('bookings/fetchOrganizer', {});
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async userFeedbacks () {
                await this.$router.push({ name: 'operator-bookings' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
        }
    }
</script>

<style lang="scss">
    .view-bookings-organizer {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }

            &__empty {
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
                text-align: center;
                margin: 10vw auto;
                max-width: 800px;
            }
        }
        &__booking{
            display: flex;
            flex-direction: column;
            padding: 16px 24px 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-bottom: 32px;
            @media all and (min-width: 768px) {
                width: 326px;
                margin-right: 32px;
                // &:last-of-type {
                //     margin-right: 0;
                // }
            }

            &__status {
                display: block;
                width: 100%;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.015em;
                color: #FFFFFF;
                background: #48A7FF;
                text-align: center;
            }
            &__trip {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__user {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                padding: 16px 0;
                border-top: 1px solid #F6F7F9;
                border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            &__price {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-top: 24px;
            }
            &__info {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                margin: 12px 0 0;
            }
        }
    }
</style>